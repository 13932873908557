<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/realty" />
      <div class="content">
        <div class="content__container mobile_center">
          <Question
            service="mortgage"
            name="alreadySold"
            question="question_7"
            successUrl="/mortgage/old-realty-price"
            negativeUrl="/mortgage/sell-during"
            :droppedSteps="dropped_steps"
            :negativeMissedSteps="dropped_steps"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'

export default {
  name: 'Mortgage_already_sold',
  components: { Backlink, Question },
  data: () => ({ dropped_steps: ['/mortgage/old-realty-price', '/mortgage/old-realty-mortgage'] })
}
</script>
